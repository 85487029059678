<template>
  <div>
    <v-toolbar
      :app="$route.name !== 'home'"
      tabs
      dark
      :class="barClass"
      style="z-index:10"
    >
      <v-btn
        icon
        @click="goBack"
        color="transparent"
        class="show-tablet"
        style="margin-left: -15px"
        v-if="showBack()"
      >
        <v-icon large aria-hidden="true" class="material-icons"
          >keyboard_arrow_left</v-icon
        >
      </v-btn>
      <b-link :to="{ path: '/' }" height100>
        <img
          v-if="!transparent"
          src="~/assets/images/buser-icon.svg"
          height="48"
          width="48"
          alt="logo Buser Brasil"
          class="mt-1"
        />
        <logo v-else :height="42" class="mt-2" />
      </b-link>
      <p class="toolbar-title hidden-md-and-up ml-3">{{ options.title }}</p>
      <v-spacer />
      <v-toolbar-items class="ma-0">
        <template v-if="loggedIn">
          <b-link :to="{ path: '/' }" height100>
            <v-btn class="hidden-sm-and-down" exact flat>
              Home
            </v-btn>
          </b-link>
          <v-btn class="hidden-sm-and-down" :to="{ name: 'promocao' }" flat>
            Promoções
          </v-btn>
          <v-btn class="hidden-sm-and-down" :to="{ name: 'salesList' }" flat>
            Vendas
          </v-btn>
          <v-btn class="hidden-sm-and-down" :to="{ name: 'consultarPax' }" flat>
            Consultar Pax
          </v-btn>
          <v-btn
            class="hidden-sm-and-down"
            href="https://www.buser.com.br/ajuda"
            target="_blank"
            flat
          >
            Ajuda
          </v-btn>
          <v-btn
            v-if="hasPdvAssociado"
            class="hidden-sm-and-down"
            :to="{ name: 'carteira' }"
            flat
          >
            Carteira
          </v-btn>
        </template>
      </v-toolbar-items>

      <v-menu v-if="loggedIn" :nudge-bottom="40" left light>
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" class="ma-0 ml-1" icon>
            <v-badge color="#64C51D" right bottom overlap>
              <span
                v-if="accountVerificationPending"
                slot="badge"
                dark
                small
                class="fw-bold"
                >!</span
              >
              <user-avatar :url="currentUser.photo_url" />
            </v-badge>
          </v-btn>
        </template>

        <v-card class="no-padding" light>
          <v-list two-line light>
            <v-list-tile avatar>
              <v-list-tile-avatar>
                <user-avatar :url="currentUser.photo_url" />
              </v-list-tile-avatar>
              <v-list-tile-content>
                <v-list-tile-title>{{ currentUser.name }}</v-list-tile-title>
                <v-list-tile-sub-title>{{
                  currentUser.email
                }}</v-list-tile-sub-title>
              </v-list-tile-content>
            </v-list-tile>
          </v-list>
          <v-divider light />
          <v-list light>
            <v-list-tile
              v-if="accountVerificationPending"
              :to="{ name: 'verificarCadastro' }"
            >
              <v-list-tile-content>
                <v-list-tile-title>Verificar Documentos</v-list-tile-title>
                <v-list-tile-sub-title class="text-secondary">
                  <small v-if="currentUser.verification_status == 'pending'"
                    >Aguarde verificação</small
                  >
                  <small v-if="currentUser.verification_status == 'denied'"
                    >Corrija seus dados</small
                  >
                </v-list-tile-sub-title>
              </v-list-tile-content>
            </v-list-tile>
            <v-list-tile @click="$router.push({ name: 'profile' })"
              >Editar perfil</v-list-tile
            >
            <v-list-tile
              @click="$router.push({ name: 'performanceRevendedor' })"
              >Performance de vendas</v-list-tile
            >
            <v-list-tile
              v-if="isGestorPDV"
              @click="$router.push({ name: 'gestaoRevendedores' })"
              >Performance dos revendedores</v-list-tile
            >
            <v-list-tile @click="logout()">Sair</v-list-tile>
          </v-list>
        </v-card>
      </v-menu>
      <v-tabs
        v-if="options.tabs"
        slot="extension"
        slider-color="primary"
        centered
        grow
        class="bu-toolbar-nav"
      >
        <v-tab v-for="tab in options.tabs" :key="tab.title" :to="tab.route">
          {{ tab.title }}
        </v-tab>
      </v-tabs>
    </v-toolbar>
  </div>
</template>

<script>
import userAvatar from '~/components/user-avatar'
import logo from '~/components/logo'
import bLink from '~/components/b-link'
import devicehelper from '~/helpers/devicehelper'
import routehelper from '~/helpers/routehelper.js'

export default {
  components: {
    logo,
    userAvatar,
    bLink
  },
  created () {
    if (process.browser) {
      this.onScroll()
      window.addEventListener('scroll', this.onScroll)
    }
  },
  destroyed () {
    if (process.browser) {
      window.removeEventListener('scroll', this.onScroll)
    }
  },
  data () {
    return {
      scrollY: 0,
      isAppleWebview: false
    }
  },
  computed: {
    options () {
      return this.$route.meta
    },
    transparent () {
      return this.options.transparent && this.scrollY < 20
    },
    barClass () {
      const classe = this.transparent
        ? 'dark transparent elevation-0'
        : 'bg-primary box-shadow'
      return classe
    },
    accountVerificationPending () {
      const currentUser = this.$store.state.auth.currentUser
      return currentUser && currentUser.verification_status === 'denied'
    },
    loggedIn () {
      return this.$store.getters['auth/loggedIn']
    },
    currentUser () {
      return this.$store.state.auth.currentUser
    },
    hasPdvAssociado () {
      return this.currentUser && this.currentUser.has_pdv
    },
    isGestorPDV () {
      return !!this.currentUser?.permissions?.GESTOR_PDV
    }
  },
  mounted () {
    this.isAppleWebview = devicehelper.isWebview() && devicehelper.isApple()
  },
  watch: {
    $route () {
      this.onScroll()
    }
  },
  methods: {
    showBack () {
      if (process.browser) {
        return devicehelper.isApple() && window.history.length > 1
      }
    },
    goBack () {
      process.browser && window.history.back()
    },
    onScroll () {
      try {
        this.scrollY = window.scrollY
      } catch (e) {
        console.log('onScroll for de window')
      }
    },
    login () {
      if (this.loggedIn) {
        routehelper.push('home', this.$route.name, null, null, this.$router)
      } else {
        const route = this.$route
        const nextRoute = {
          name: route.name,
          params: route.params,
          query: route.query
        }
        const params = {
          convite: this.$route.query.convite,
          next: nextRoute
        }
        routehelper.push('login', this.$route.name, params, null, this.$router)
      }
    },
    logout () {
      this.$store.dispatch('auth/logout').then(() => {
        routehelper.push('login', this.$route.name, null, null, this.$router)
      })
    }
  }
}
</script>

<style lang="scss">
.toolbar__extension {
  height: 48px !important;
}

.toolbar-title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 0;
  width: 100%;
}

.bu-toolbar-nav {
  .theme--dark.v-tabs__bar {
    background-color: transparent !important;
  }
}
</style>

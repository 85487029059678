import { render, staticRenderFns } from "./floating-whatsapp-button.vue?vue&type=template&id=cfa40176&scoped=true"
import script from "./floating-whatsapp-button.vue?vue&type=script&lang=js"
export * from "./floating-whatsapp-button.vue?vue&type=script&lang=js"
import style0 from "./floating-whatsapp-button.vue?vue&type=style&index=0&id=cfa40176&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cfa40176",
  null
  
)

export default component.exports
const metaVuetify = {
  home: { showFooter: false },
  invitation: {
    transparent: false,
    hideNotLoggedInMenu: true,
    hideBottomNav: true
  },
  searchPage: { back: { name: 'home' }, showFooter: true, hideBottomNav: true },
  searchOriginPage: {
    back: { name: 'home' },
    showFooter: true,
    hideBottomNav: true
  },
  searchDestinationPage: { back: { name: 'home' }, showFooter: true },
  alterarReserva: { back: { name: 'home' }, title: 'Alterar reserva' },
  group: { back: { name: 'home' }, hasPagination: true },
  destinos: { showFooter: true, bottomnavItem: 'home' },
  topRotas: { showFooter: true, bottomnavItem: 'home' },
  carteira: { bottomnavItem: 'carteira' },
  travels: { bottomnavItem: 'travels' },
  travel: { bottomnavItem: 'travels', back: { name: 'home' } },
  friends: { bottomnavItem: 'friends' },
  convide: { transparent: false, showFooter: false, hideToolbar: true },
  terms: { transparent: true, showFooter: true, bottomnavItem: 'terms' },
  about: { transparent: true, showFooter: true, bottomnavItem: 'about' },
  firstTravel10Page: { transparent: true, showFooter: true },
  firstTravelGratisPage: { transparent: true, showFooter: true },
  firstTravelGratisOldPage: { transparent: true, showFooter: true },
  diaDosNamorados: { transparent: true, showFooter: true },
  promocao: { transparent: true, showFooter: true },
  reservaConfirmada: {
    transparent: false,
    showFooter: false,
    hideNotLoggedInMenu: true
  },
  login: { transparent: false, showFooter: false, hideNotLoggedInMenu: true },
  profile: { showPhoneConfirmationAlert: true }
}

export const routesVuetify = [
  // Principais
  {
    path: '/',
    name: 'home',
    component: 'home-vuetify',
    meta: metaVuetify.home
  },
  {
    path: '/onibus',
    name: 'rotasAtivas',
    component: 'rotas-ativas',
    meta: metaVuetify.searchPage
  },
  {
    path: '/onibus/para/:destino',
    name: 'searchDestinationPage',
    component: 'groups/search-page',
    meta: metaVuetify.searchDestinationPage
  },
  {
    path: '/onibus/:origem/:destino',
    name: 'searchPage',
    component: 'groups/search-page',
    meta: metaVuetify.searchPage
  },
  {
    path: '/onibus/:origem',
    name: 'searchOriginPage',
    component: 'groups/search-page',
    meta: metaVuetify.searchOriginPage
  },
  {
    path: '/erro',
    name: 'errorhome',
    component: 'errorhome',
    meta: metaVuetify.login
  },
  {
    path: '/link-pagamento',
    name: 'linkPagamento',
    component: 'link-pagamento',
    meta: metaVuetify.login
  },
  {
    path: '/promotor',
    name: 'performanceRevendedor',
    component: 'performance-revendedor',
    meta: metaVuetify.login
  },
  {
    path: '/revendedores',
    name: 'gestaoRevendedores',
    component: 'gestao-revendedores',
    meta: metaVuetify.login
  },
  {
    path: '/consultar-pax',
    name: 'consultarPax',
    component: 'consultar-pax',
    meta: metaVuetify.login
  },
  // Promoções
  {
    path: '/promo/:code',
    name: 'promocaoCode',
    component: 'promocao',
    meta: metaVuetify.promocao
  },
  {
    path: '/promo',
    name: 'promocao',
    component: 'promocao',
    meta: metaVuetify.promocao
  },
  // Perfil - NÃO INDEXADAS
  {
    path: '/perfil',
    name: 'profile',
    component: 'user/profile',
    meta: metaVuetify.profile
  },
  {
    path: '/perfil/carteira',
    name: 'carteira',
    component: 'user/carteira',
    meta: metaVuetify.carteira
  },
  {
    path: '/perfil/completar-cadastro',
    name: 'completeRegistration',
    component: 'user/complete-registration-page',
    meta: metaVuetify.login
  },
  {
    path: '/perfil/confirmar-telefone',
    name: 'confirmPhone',
    component: 'user/confirm-phone-page',
    meta: metaVuetify.login
  },
  {
    path: '/perfil/verificar-cadastro',
    name: 'verificarCadastro',
    component: 'user/verificar-cadastro-page'
  },
  {
    path: '/perfil/redefinir_senha',
    name: 'resetPassword',
    component: 'user/reset-password'
  },
  {
    path: '/perfil/entrar/:step?/:origin?',
    name: 'login',
    component: 'user/login-page',
    meta: metaVuetify.login
  },
  {
    path: '/cpl/:token',
    name: 'telefoneConfirmado',
    component: 'user/confirmed-phone-page',
    meta: metaVuetify.login
  },
  {
    path: '/verificar-telefone/:token',
    name: 'telefoneVerificado',
    component: 'user/confirmed-phone-sent-by-staff-page',
    meta: metaVuetify.login
  },
  // Reserva - NÃO INDEXADAS
  {
    path: '/reserva/vendas',
    name: 'salesList',
    component: 'travels/sales-list',
    meta: metaVuetify.travels
  },
  {
    path: '/reserva/viagens/:id',
    name: 'travel',
    component: 'travels/travel',
    meta: metaVuetify.travel
  },
  {
    path: '/reserva/grupos/:idIda/:idVolta?',
    name: 'group',
    component: 'groups/group',
    meta: metaVuetify.group
  },
  {
    path: '/reserva/viagens/:id/alterar',
    name: 'alterarReserva',
    component: 'alterar-reserva',
    meta: metaVuetify.alterarReserva
  },
  {
    path: '/reserva/confirmada',
    name: 'reservaConfirmada',
    component: 'reserva-confirmada',
    props: true,
    meta: metaVuetify.reservaConfirmada
  },
  {
    path: '/reserva/alterada',
    name: 'reservaAlterada',
    component: 'reserva-alterada',
    props: true,
    meta: metaVuetify.reservaConfirmada
  }
]
